var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "payment-type payment-type-redirect payment-type-satispay" },
    [
      _vm.paymentType.title
        ? _c("div", {
            staticClass: "payment-type-title",
            domProps: { innerHTML: _vm._s(_vm.paymentType.title) }
          })
        : _vm._e(),
      _c("div", {
        staticClass: "payment-type-description",
        domProps: { innerHTML: _vm._s(_vm.paymentType.descr) }
      }),
      _c("PrebilledAmount", {
        attrs: {
          mode: _vm.mode,
          paymentTypeId: _vm.paymentType.paymentTypeId,
          "order-id": _vm.orderId
        }
      }),
      _vm.$te("paymentTypeList.redirectText." + _vm.paymentType.paymentTypeId)
        ? _c("div", {
            staticClass: "redirect-text",
            domProps: {
              innerHTML: _vm._s(
                _vm.$t(
                  "paymentTypeList.redirectText." +
                    _vm.paymentType.paymentTypeId
                )
              )
            }
          })
        : _vm._e(),
      _c("ResponseMessage", { attrs: { response: _vm.response } }),
      _c(
        "v-row",
        { staticClass: "d-flex justify-end" },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: "12", md: "6" }
            },
            [
              _vm.options.back.show &&
              _vm.mode != "order" &&
              !_vm.options.hidePrevButton
                ? _c(
                    "v-btn",
                    {
                      class: _vm.options.back.class,
                      attrs: {
                        color: _vm.options.back.color,
                        outlined: _vm.options.back.outline,
                        icon: _vm.options.back.icon,
                        link: _vm.options.back.link,
                        depressed: "",
                        disabled: _vm.loading,
                        large: _vm.options.back.large
                      },
                      on: { click: _vm.goBack }
                    },
                    [_vm._v(" " + _vm._s(_vm.options.back.label) + " ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-end",
              attrs: { cols: "12", md: "6" }
            },
            [
              _vm.options.confirm.show
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mt-3 mt-sm-0 ml-2 gateway-btn",
                      class: _vm.options.confirm.class,
                      attrs: {
                        color: _vm.options.confirm.color,
                        outlined: _vm.options.confirm.outline,
                        depressed: _vm.options.confirm.depressed,
                        large: _vm.options.confirm.large
                      },
                      on: { click: _vm.buy }
                    },
                    [_vm._v(" " + _vm._s(_vm.options.confirm.label) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.$te(`paymentTypeList.${_vm.paymentType.gatewayClient}.caption`)
        ? _c("i18n", {
            staticClass: "caption",
            attrs: {
              path: `paymentTypeList.${_vm.paymentType.gatewayClient}.caption`,
              tag: "div"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }